import React from 'react';
import loadable from '@loadable/component';
import {graphql} from 'gatsby';
import {Location} from '@reach/router';

const PageHeader = loadable(() => import('../components/PageHeader'));
const Layout = loadable(() => import('../components/Layout'));
const Gallery = loadable(() => import('../components/Gallery'));


// Export Template for use in CMS preview
export const CabinetPageTemplate = ({
  title,
  subtitle,
  featuredImage,
  gallery
}) => (
  <Location>
    {({ location }) => {
      return (
        <main className="Blog">
          <PageHeader
            title={title}
            subtitle={subtitle}
            backgroundImage={featuredImage}
          />

          <section className="section mb-8">
            <div className="container">
              <h2>Le cabinet en image</h2>
              <hr className="mb-8"></hr>
              <Gallery images={gallery}/>
            </div>
          </section>

        </main>
      )
    }}
  </Location>
);

// Export Default CabinetPage for front-end
const CabinetPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <CabinetPageTemplate
      {...page}
      {...page.fields}
      {...page.frontmatter}
    />
  </Layout>
);

export default CabinetPage

export const pageQuery = graphql`
  query CabinetPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      ...Gallery
      fields {
        contentType
      }
      frontmatter {
        title
        excerpt
        template
        subtitle
        featuredImage
      }
    }
  }
`;
